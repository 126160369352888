import { render, staticRenderFns } from "./SchoolAwayAssignmentPage.vue?vue&type=template&id=dd5d9c7e&scoped=true&"
import script from "./SchoolAwayAssignmentPage.vue?vue&type=script&lang=js&"
export * from "./SchoolAwayAssignmentPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd5d9c7e",
  null
  
)

export default component.exports
<template>
  <SchoolTeacherAwayControl v-if="type === 1" />
  <SchoolStaffAwayControl v-else-if="type === 2" />
</template>

<script>
import SchoolTeacherAwayControl from "@/modules/school/components/management/away/assignment/control/SchoolTeacherAwayControl";
import SchoolStaffAwayControl from "@/modules/school/components/management/away/assignment/control/SchoolStaffAwayControl";
export default {
  name: "SchoolAwayTableControl",
  components: { SchoolStaffAwayControl, SchoolTeacherAwayControl },
  data() {
    return {
      type: 1,
    };
  },
  methods: {
    checkRoute(name) {
      switch (name) {
        case "schoolAwayAssignment":
          this.type = 1;
          break;
        case "staffAwayAssignment":
          this.type = 2;
          break;
        default:
          this.type = 1;
          break;
      }
    },
  },
  watch: {
    $route(to, from) {
      console.log(to, from);

      this.checkRoute(to.name);
    },
  },
};
</script>

<style scoped></style>
